// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';
/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';
/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/gutenberg/acf-accordion/index.js'; import '../blocks/gutenberg/acf-case-studies-block/index.js'; import '../blocks/gutenberg/acf-cta-block/index.js'; import '../blocks/gutenberg/acf-example-block/index.js'; import '../blocks/gutenberg/acf-hero-block/index.js'; import '../blocks/gutenberg/acf-link-repeater/index.js'; import '../blocks/gutenberg/acf-logos-block/index.js'; import '../blocks/gutenberg/acf-section-5050-image-text/index.js'; import '../blocks/gutenberg/acf-section-block/index.js'; import '../blocks/gutenberg/acf-spacer-block/index.js'; import '../blocks/gutenberg/acf-steps-block/index.js'; import '../blocks/gutenberg/acf-testimonials-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
  addSvgUnderline();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  document.addEventListener('scroll', function () {
    const header = document.querySelector('.header');
    if (window.scrollY > 0) {
      header.classList.add('scroll');
    } else {
      header.classList.remove('scroll');
    }
  });
});

if (typeof wp !== 'undefined' && wp.i18n && wp.richText && wp.blockEditor) {
  (function () {
    const { __ } = wp.i18n;
    const { registerFormatType, toggleFormat } = wp.richText;
    const { RichTextToolbarButton } = wp.blockEditor;

    function registerFormat(
      name,
      label,
      icon = 'block-default',
      namespace = 'fwp'
    ) {
      registerFormatType(namespace + '/' + name, {
        title: label,
        tagName: 'span',
        className: name,
        edit: function (props) {
          return wp.element.createElement(RichTextToolbarButton, {
            icon: icon,
            isActive: props.isActive,
            title: label,
            onClick: function () {
              props.onChange(
                toggleFormat(props.value, {
                  type: namespace + '/' + name,
                })
              );
            },
          });
        },
      });
    }

    registerFormat('double-underline', __('double underline'));
    // registerFormat('highlight-text--accent', __('highlight text'));
  })();
}

// function logosMovingLine() {
//   let logosSection = $('.acf-logos-block__logos');
//   let logosWrapper = $('.acf-logos-block__logos-wrapper');
//   let logosItems = $('.acf-logos-block__logo');
//   logos;
// }
function addSvgUnderline() {
  var svg = `
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 -13 190 42' width='100%' height='29'>
        <defs>
            <style>
                .cls-1 {
                fill: #fe001c;
                opacity: 0; /* Start hidden */
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-fill-mode: forwards;
                }

                /* Animation for the first path to appear from left to right */
                .path1 {
                animation-name: appearLeftToRight;
                }

                @keyframes appearLeftToRight {
                0% {
                opacity: 0;
                clip-path: inset(0 100% 0 0);
                }
                100% {
                opacity: 1;
                clip-path: inset(0 0 0 0);
                }
                }

                /* Animation for the second path to appear from right to left */
                .path2 {
                animation-name: appearRightToLeft;
                }

                @keyframes appearRightToLeft {
                0% {
                opacity: 0;
                clip-path: inset(0 0 0 100%);
                }
                100% {
                opacity: 1;
                clip-path: inset(0 0 0 0);
                }
                }
            </style>
        </defs>
        <g transform='scale(0.2, 0.2) translate(0, -50)'>
            <path class='cls-1 path1' d='M364.74,4.18c74.97-1.24,130.45-2.05,185.92-3.1,30.48-.58,60.96-1.64,91.45-.71,32.48.99,64.87,3.66,97.12,7.44,62.08,7.29,124.21,13.18,186.83,11.51,12.97-.35,26.21-1.44,38.78,2.17,13.39,3.85,26.36,9.32,37.38,18.22,7.97,6.43,7.03,21.21-2.32,25.42-17.4,7.83-35.05,15.51-54.71,15.83-2.15.03-4.32-.28-6.46-.59-2.97-.43-5.46-1.68-6.32-4.84-.92-3.36-.9-6.62,1.83-9.29,3.73-3.64,8.08-6.39,12.63-8.87,1.57-.86,4.15-1.47,3.64-3.49-.48-1.9-3.12-1.23-4.81-1.37-19.27-1.58-38.59-2.44-57.92-2.02-35.74.78-71.21-2.58-106.66-6.42-28.83-3.13-57.7-4.93-86.74-4.92-63.48.02-126.96-.68-190.44-.96-47-.21-93.99.74-140.84,4.43-46.64,3.67-93.3,7.23-139.7,13.46-18.18,2.44-36.52,2.75-54.84,3.31-23.44.73-46.98.39-70.02,6.03-14.22,3.48-28.46,7.01-43.06,8.4-10.58,1.01-21.25,1.99-31.68-1.44-13.06-4.29-19.28-14.59-23.2-26.72-.96-2.96-.85-6.42.79-9.28,5.8-10.12,13.91-17.76,25.85-19.07,58.69-6.41,117.67-7.66,176.62-8.82,60.13-1.19,120.22-4.08,160.87-4.31Z'/>
            <path class='cls-1 path2' d='M378.68,92.55c3.59.38,8.75-.2,13.72-1.57,11.53-3.18,23.25-4.07,35.12-3.55,18.87.83,37.57-.36,56.16-3.84,6.41-1.2,12.97-.26,19.45.06,13.34.66,26.6.45,39.75-2.42,12.17-2.65,24.5-2.96,36.73-.66,16.32,3.07,32.79,2.98,49.27,3.22,25.48.37,50.96.76,76.44,1.13,14.21.21,28.28-1.44,42.22-3.82,20.41-3.48,40.68-4.02,60.98.68,5.09,1.18,10.42,1.36,15.2-.96,10.66-5.18,20.65-2.73,30.6,1.91,4.97,2.31,10.09,4.32,14.91,6.9,11.3,6.06,17.68,15.8,20.04,28.25,1.22,6.42-2.24,10.5-7.44,13.33-10.99,5.97-22.82,7.52-34.86,4.78-46.07-10.48-92.99-14.73-139.85-19.23-33.51-3.22-67.22-2.44-100.87-2.28-32.81.15-65.64.31-98.44-.22-18.46-.3-36.91-1.97-55.36-3.04-23.84-1.38-47.61.55-71.4,1.56-14.98.64-29.91,1.86-44.83,3.21-6.83.62-13.66.18-20.31-1.67-2.06-.57-4.19-1.3-5.93-2.49-3.03-2.06-6.6-4.63-5.42-8.7,1.07-3.67,5.25-2.73,8.29-2.91,5.48-.34,10.98-.54,16.47-.67,5.04-.12,10.01-.22,14.12-3.83.7-.61,1.85-1.03,2.78-1,10.36.36,20.43-3.13,32.44-2.15Z'/>
        </g>
    </svg>
    `;
  $('.double-underline').each(function () {
    $(this).append(svg);
  });
}
